import {
  WebGLRenderer,
  sRGBEncoding,
  PCFSoftShadowMap,
  ReinhardToneMapping,
  ACESFilmicToneMapping,
  WebGLRenderTarget,
  WebGLMultisampleRenderTarget,
  LinearFilter,
  Vector2,
  TextureLoader,
  RGBFormat,
  RepeatWrapping,
  SubtractiveBlending,
  Color
} from 'three'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
// import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js'
import { OutlineEffect } from 'three/examples/jsm/effects/OutlineEffect.js'

export class Renderer {
  constructor(scene, camera) {
    this.scene = scene
    this.camera = camera
    
    this.usePostprocess = true

    this.resize = this.resize.bind(this)
    this.update = this.update.bind(this)

    this.setInstance()
    this.setPostProcess()
  }

  setInstance () {
    this.clearColor = '#f4aaf5'

    // Renderer
    this.renderer = new WebGLRenderer({
      alpha: false,
      antialias: true,
    })
    this.renderer.setClearColor(this.clearColor)

    // this.renderer.physicallyCorrectLights = true
    this.renderer.outputEncoding = sRGBEncoding
    // this.renderer.shadowMap.type = PCFSoftShadowMap
    this.renderer.shadowMap.enabled = true
    // this.renderer.toneMapping = ReinhardToneMapping
    // this.renderer.toneMapping = ACESFilmicToneMapping
    // this.renderer.toneMappingExposure = 1.3
    // this.renderer.gammaOutPut = true
    // this.renderer.gammaFactor = 2.2

    this.context = this.renderer.getContext()

    // Add stats panel
    if (this.stats) {
      this.stats.setRenderPanel(this.context)
    }
  }

  setPostProcess () {
    this.postProcess = {}


    /**
     * Render pass
     */
    this.effect = new OutlineEffect(this.renderer, {
      defaultThickness: .0025
    })
    this.postProcess.renderPass = new RenderPass(this.scene, this.camera)

    /**
     * Effect composer
     */
    const RenderTargetClass = window.devicePixelRatio >= 2 ? WebGLRenderTarget : WebGLMultisampleRenderTarget
    // const RenderTargetClass = WebGLRenderTarget
    this.renderTarget = new RenderTargetClass(
      window.innerWidth,
      window.innerHeight,
      {
        generateMipmaps: false,
        minFilter: LinearFilter,
        magFilter: LinearFilter,
        format: RGBFormat,
        encoding: sRGBEncoding
      }
    )
    this.postProcess.composer = new EffectComposer(this.renderer, this.renderTarget)
    this.postProcess.composer.setSize(window.innerWidth, window.innerHeight)
    this.postProcess.composer.setPixelRatio(window.devicePixelRatio)

    this.postProcess.composer.addPass(this.postProcess.renderPass)
  }

  resize ({width, height}) {
    // Instance
    this.renderer.setSize(width, height)
    this.renderTarget.setSize(width, height)
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.effect.setSize(width, height)

    // Post process
    this.postProcess.composer.setSize(width, height)
    this.postProcess.composer.setPixelRatio(window.devicePixelRatio)

    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()
  }

  update () {
    // console.log(this.renderer)
    if (this.usePostprocess) {
      // this.postProcess.composer.render()
      this.effect.render(this.scene, this.camera)
    }
    else {
      this.renderer.render(this.scene, this.camera)
    }

    if (this.stats) {
      this.stats.afterRender()
    }
  }

  get canvas () {
    return this.renderer.domElement
  }

  destroy () {
    this.renderer.renderLists.dispose()
    this.renderer.dispose()
    this.renderTarget.dispose()
    this.postProcess.composer.renderTarget1.dispose()
    this.postProcess.composer.renderTarget2.dispose()
  }
}
