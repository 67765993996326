<template>
  <div class="u-wrapper-panel u-row u-middle u-center">
    <div>
      <router-link
        :to="{...$local('Level'), params: { level: level.slug }}"
        class="u-block u-full-width u-marg-t-sm"
        v-for="level in levels"
      >
        {{ level.title }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    levels () {
      return this.$store.getters['data/levels']
    }
  }
}
</script>