export const keypoint = `
  id
  title
  slug
  subtitle: sousTitre
  zoom
  position {
    x
    y
    z
  }
  look {
    x
    y
    z
  }
  progress
  multipleTexts {
    ... on multipleTexts_multipleTexts_BlockType {
      id
      text
    }
  }
`

export const chapter = `
  id
  title
  slug
  progress
`

export const levels = `
  id
  title
  subtitle: sousTitre
  slug
  image {
    id
    url
  }
`