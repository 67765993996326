import { api } from '@/store/interfaces/apiCraft'

import { ref, reactive, toRefs } from 'vue'

export default {
  namespaced: true,

  state: {
    entries: [],
    levels: [],
    loaded: false
  },

  getters: {
    entries: state =>{
      return state.entries
    },
    levels: state =>{
      return state.levels
    },
    levelBySlug: state => slug => {
      return state.levels.find(level => level.slug === slug)
    },
    keypointBySlug: (state, getters) => (args) => {
      const level = getters.levelBySlug(args.level)
      return level.keypoints.find(keypoint => keypoint.slug === args.keypoint)
    },
    loaded: state =>{
      return state.loaded
    }
  },

  actions: {
    async levels ({ commit, state }) {
      return new Promise(resolve => {
        const { request, cache } = api.getLevels()
        cache.then(res => {
          if (res !== null) {
            commit('levels', res)
            commit('loaded', true)
            resolve()
          }
        })

        request.then(res => {
          commit('levels', res)
          commit('loaded', true)
          resolve()
        })
      })
    },

    async level ({ commit, state }, { slug }) {
      return new Promise(resolve => {
        const { request, cache } = api.getLevel( slug )
        cache.then(res => {
          if (res !== null) {
            commit('level', res)
            commit('loaded', true)
            resolve()
          }
        })

        request.then(res => {
          commit('level', res)
          commit('loaded', true)
          resolve()
        })
      })
    }
  },

  mutations: {
    loaded (state, payload) {
      state.loaded = payload
    },
    entries (state, payload) {
      state.entries = payload
    },
    levels (state, levels) {
      levels.forEach(level => {
        level.keypoints = level.chapters.reduce((accumulator, current) => {
          accumulator.push(...current.keypoints)
          return accumulator
        }, [])
      })
      state.levels = levels
    }
  }
}