<template>
  <div
    @mousemove="onMouseMove"
    class="u-wrapper-panel"
  >
    <keypoint
      v-for="keypoint in keypoints"
      :progress="progress"
      :position="keypoint.position[0]"
      :baseProgress="keypoint.progress * .01"
      :key="keypoint.slug"
      :title="keypoint.title"
      :subTitle="keypoint.subTitle"
      :to="{...$local('Keypoint'), params: {level: $route.params.level, keypoint: keypoint.slug}}"
    />
    <chapter
      v-for="(chapter, index) in chapters"
      :progress="progress"
      :baseProgress="chapter.progress * .01"
      :position="chapter.position"
      :key="chapter.slug"
      :data="chapter"
      :index="index"
    />

    <router-view v-slot="{ Component }">
      <transition name="t-fader">
        <component :is="Component" />
      </transition>
    </router-view>

    <div class="btn__move u-center" @click="askToMove" v-if="$device.isIphone || $device.isIpad" >
      ask to move
    </div>
  </div>
</template>

<script>
import data from '@/assets/data'

import Keypoint from '@/components/Keypoint'
import Chapter from '@/components/Chapter'

import { OrbitControls } from 'shimmer'

import { clamp, mapper } from '@/utils/Maths'

import { Vector2 } from 'three'

import { webGL } from '@/webGL/WebGL'
import { City } from '@/webGL/objects/City'
import { CameraPath } from '@/webGL/CameraPath'

export default {
  components: {
    Keypoint,
    Chapter
  },

  computed: {
    level () {
      return this.$store.getters['data/levelBySlug'](this.$route.params.level)
    },
    chapters () {
      const chapters = [...this.level.chapters]

      chapters.forEach(chapter => {
        chapter.position = this.cameraPath.getPointAtProgess(chapter.progress * .01)
        chapter.position.y += .05
      })

      return chapters
    },
    chaptersPosition () {
      return this.level.chapters
    },
    keypoints () {
      return this.level.keypoints
    },
    isActive () {
      return this.$route.name.includes('Level')
    }
  },

  data () {
    return {
      progress: 0
    }
  },

  created () {
    this.cameraPath = new CameraPath({
      pathName: this.$route.params.level,
      isTouch: this.$device.isTouch
    })
    this.city = new City(this.$route.params.level)

    this.isAccelerometer = false

    webGL.scene.add(this.city)

    if (this.$route.query.debug) {
      this.cameraPath.debug = true
    }

    this.accelerometer = {
      // delta: new Vector2(),
      sum: new Vector2(),
      normalize: new Vector2(),
      smooth: new Vector2(),
      // speed: new Vector2(),
      base: new Vector2()
    }
    this.smooth = {
      currentPos: new Vector2(),
      lastPos: new Vector2(),
      delta: new Vector2(),
      pos: new Vector2()
    }

    this.$hub.on('mousepos:reinit', () => {
      this.smooth.currentPos.x = this.smooth.currentPos.y = 0
      this.smooth.pos.x = this.smooth.pos.y = 0

      this.accelerometer.sum.x = this.accelerometer.sum.y = 0
      this.accelerometer.normalize.x = this.accelerometer.normalize.y = 0
      this.accelerometer.smooth.x = this.accelerometer.smooth.y = 0
      this.accelerometer.base.x = this.accelerometer.base.y = 0
    })


    if (window.DeviceOrientationEvent) {
      this.isAccelerometer = true
      window.addEventListener('deviceorientation', this.onMotion)
    }
  },

  mounted () {
    window.addEventListener('pointermove', this.onPointerMove)
  },

  methods: {
    askToMove () {
      if (this.$device.isIphone || this.$device.isIpad) {
        DeviceOrientationEvent.requestPermission()
      }
    },

    onMotion (event) {
      if (this.accelerometer.base.x === 0 && this.accelerometer.base.y === 0) {
        this.accelerometer.base.x = event.alpha
        this.accelerometer.base.y = event.beta
      }

      // vertical
      // console.log('event', event.rotationRate.alpha)
      // horizontal
      // console.log('event', event.rotationRate.gamma)
      // horizontal
      // console.log('event', event.acceleration.x)
      // vertical
      // console.log('event', event.acceleration.y)

      // this.accelerometer.speed.x += event.acceleration.x * .01
      // this.accelerometer.speed.y += event.acceleration.y * .01

      // this.accelerometer.sum.x += this.accelerometer.speed.x * .1
      // this.accelerometer.sum.y += this.accelerometer.speed.y * .1

      // this.accelerometer.sum.x -= (event.gamma + event.alpha) * .05 * .5

      // this.accelerometer.sum.x -= event.gamma * .05
      // this.accelerometer.sum.y += event.beta * .05

      // 8 is an orbitrary value. Lower it will lower max value
      this.accelerometer.sum.x = clamp(this.accelerometer.base.x - event.alpha, -30, 30)
      this.accelerometer.sum.y = clamp(-(this.accelerometer.base.y - event.beta), -30, 30)

      this.accelerometer.normalize.x = mapper(this.accelerometer.sum.x, -30, 30, -1, 1)
      this.accelerometer.normalize.y = mapper(this.accelerometer.sum.y, -30, 30, -1, 1)
    },

    computedSmoothAccelerometer () {
      this.accelerometer.smooth.x += (this.accelerometer.normalize.x - this.accelerometer.smooth.x) * .3
      this.accelerometer.smooth.y += (this.accelerometer.normalize.y - this.accelerometer.smooth.y) * .3

      this.cameraPath.accelerometer = this.accelerometer.smooth
    },

    onUpdate (time) {
      if (!this.isActive) { return }

      this.progress = this.cameraPath.scroll
      this.$store.commit('global/progress', this.progress)

      if (!this.$device.isTouch) {
        this.computedSmoothMouse()
      }

      if (this.isAccelerometer) {
        this.computedSmoothAccelerometer()
      }
    },

    computedSmoothMouse () {
      this.smooth.currentPos.x += (this.smooth.pos.x - this.smooth.currentPos.x) * .1
      this.smooth.currentPos.y += (this.smooth.pos.y - this.smooth.currentPos.y) * .1
      this.smooth.delta.subVectors(this.smooth.currentPos, this.smooth.lastPos)
      this.smooth.lastPos.copy(this.smooth.currentPos)
      this.cameraPath.mousePos = this.smooth.currentPos
    },

    onPointerMove (event) {
      let pos = this.$device.getPointerPosition(event)
      this.smooth.pos.copy(new Vector2(( pos.x / this.$device.width ) * 2 - 1, - ( pos.y / this.$device.height ) * 2 + 1))
    }
  },

  beforeUnmount () {
    this.city.onDestroy()
    webGL.scene.remove(this.city)
    window.removeEventListener('pointermove', this.onPointerMove)
  },
}
</script>

<style lang="stylus">

.btn__move
  position absolute
  bottom 20px
  left calc(50% - 100px)
  width 200px

</style>