<template>
  <div class="u-wrapper-panel u-row">
    <div class="u-w4of12 u-marg-l-7of12 u-full-height u-row u-middle">
      <div class="u-radius u-bg-white u-full-width u-pad-md">
        {{ data.title }}

        <div class="u-marg-t-sm" v-for="text in data.multipleTexts">
          {{ text.text }}
        </div>

        <div class="u-marg-t-sm">
          <router-link :to="{...$local('Level'), params: $route.params }" class="u-marg-t-sm">
            back
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    data () {
      return this.$store.getters['data/keypointBySlug']({
        level: this.$route.params.level, 
        keypoint: this.$route.params.keypoint
      })
    }
  },

  created () {
    this.$hub.emit('keypoint:on', this.data)
    // keepData is to prevent data from being clear before unmount
    this.keepData = {...this.data}
  },

  beforeUnmount () {
    this.$hub.emit('keypoint:off', this.keepData)
  }
}
</script>