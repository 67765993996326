<template>
  <component
    :is="to.name ? 'router-link' : 'div'"
    :to="to"
    ref="root"
    class="keypoint u-cursor-pointer"
    @click="$emit('click')"
    :style="{opacity: opacity}"
  >
    <div class="keypoint__dot">
      <div class="keypoint__dot__main u-box-1by1" />
      <div class="keypoint__dot__pulse"></div>
      <div class="keypoint__dot__heart"></div>
    </div>
    <div class="keypoint__name u-black">
      <div class="keypoint__name__title u-bg-white">
        {{ title }}
      </div>
      <div class="keypoint__name__subtitle u-bg-white">
        {{ subTitle }}
      </div>
    </div>
  </component>
</template>

<script>
import { webGL } from '@/webGL/WebGL'

import Object2d from '@/plugins/Dom3d/mixin2d'
import Opacity2D from './Opacity2D'

export default {
  mixins: [Object2d, Opacity2D],

  emits: ['click'],

  created () {
    this._camera = webGL.camera
  },

  props: {
    to: {
      type: Object,
      default: () => {return {}}
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    }
  },

  methods: {
    onUpdate () {
      this.computeOpacity()
      this._onUpdate()
    }
  },
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

.keypoint
  position absolute

  &:hover &__name
    opacity 1

  &__name
    position absolute
    top 0
    left 30px
    opacity 0
    transition opacity .3s linear

    &__title
      border-radius 15px  15px 15px 0

    &__subtitle
      border-radius 0 15px 15px 15px

  &__dot
    border-radius 50%
    width 20px
    opacity 1

    &__main
      border-radius 50%
      background-color $colors.white
      opacity .5

    &__heart
      position absolute
      background: $colors.white
      height 80%
      width 80%
      top 10%
      left 10%
      border-radius 50%

    &__pulse
      position absolute
      border solid 2px $colors.white
      height 150%
      width 150%
      top -25%
      left -25%
      border-radius 50%
      opacity 0
      will-change opacity transform
      animation 2s ease-in-out-quad infinite pulse

@keyframes pulse
  0%
    transform: translateZ(0) scale(.5)
    opacity 0
  20%
    opacity 1

  100%
    transform: translateZ(0) scale(1)
    opacity 0
</style>