<template>
  <div
    id="main"
    ref="main"
    :class="{'is-full': $route.meta.isFull}"
    class="u-full-width u-relative u-overflow-hidden u-antialiased"
  >
    <symbols-svg />
    <div ref="containerWebGL" class="u-wrapper-panel"/>
    <router-view v-slot="{ Component }">
      <transition name="t-fader">
        <component v-if="isLoaded" :is="Component" />
      </transition>
    </router-view>

    <debugger v-if="$route.query.debug" />

    <!-- <div ref="containerCSS3D" class="u-wrapper-panel u-overflow-hidden"/> -->
    <img alt="Vue logo" src="./assets/logo.png">
  </div>
</template>

<script>
import App from './App'

import SymbolsSvg from '@/components/SymbolsSvg'
import Debugger from '@/components/Debugger'

import { webGL } from '@/webGL/WebGL'

export default {
  name: 'App',

  mixins: [App],

  computed: {
    isLoaded () {
      return this.$store.getters['data/loaded']
    }
  },

  components: {
    SymbolsSvg,
    Debugger
  },

  created () {
    this.$store.dispatch('data/levels')
  },

  mounted () {
    webGL.isMobile = this.$device.isMobile
  },

  watch: {
    async $route (to, from) {
      // await this.$store.dispatch('data/levels')

      // if (!from.name && !to.query.debug) {
      //   const first = this.$store.getters['data/levels'][0]
      //   this.$router.push({...this.$local('Level'), params: { level: first.slug }})
      // }
    }
  }
}
</script>

<style lang="stylus" src="./styles/theme.styl"/>

<style lang="stylus">
// @import '~@/styles/settings/variables'

#main
  min-height 100vh

  &.is-full
    position fixed
    top .5px
    bottom .5px
    left 0
    right 0
    overflow hidden
    min-height auto

</style>