import { Component, LoaderManager } from 'shimmer'
import { webGL } from '@/webGL/WebGL'

import { polarToCartesian } from '@/utils/Maths'

import {
  AmbientLight,
  DirectionalLight,
  SphereBufferGeometry,
  MeshToonMaterial,
  Color,
  TextureLoader,
  NearestFilter,
  Mesh
} from 'three'

export class City extends Component {
  constructor (city) {
    super()

    this.promise = LoaderManager.load(city)

    this.promise.then(obj => {
      this.add(obj[0].object)
    })

    this.addLight()
  }

  addLight () {
    this.ambient = new AmbientLight(0xffffff, 1)
    this.add(this.ambient)

    // this.directional1 = new DirectionalLight(0xffffff, 1)
    // this.directional1.position.set(-15, 15, 20)
    // this.directional1.lookAt(0, 0, 0)
    // this.directional1.castShadow = true
    // this.directional1.shadow.bias = -0.0004
    // this.directional1.shadow.camera.far = 70
    // this.directional1.shadow.camera.near = 0.001
    // this.directional1.shadow.mapSize.width = 1024
    // this.directional1.shadow.mapSize.height = 1024
    // this.directional1.shadow.camera.updateProjectionMatrix()
    // this.add(this.directional1)

    // this.directional2 = new DirectionalLight(0xffffff, .7)
    // this.directional2.position.set(-8, 7, 20)
    // this.directional2.lookAt(0, 0, 0)
    // this.directional2.castShadow = true
    // this.directional2.shadow.bias = -0.0004
    // this.directional2.shadow.camera.far = 70
    // this.directional2.shadow.camera.near = 0.001
    // this.directional2.shadow.mapSize.width = 1024
    // this.directional2.shadow.mapSize.height = 1024
    // this.directional2.shadow.camera.updateProjectionMatrix()
    // this.add(this.directional2)
  }

  onDestroy () {
    const toRemove = []
    this.traverse(child => {
      toRemove.push(child)

      if (child.isMesh) {
        if (child.material.map) {
          child.material.map.dispose()
        }
        child.material.dispose()
        child.geometry.dispose()
      }
    })

    toRemove.forEach(child => {
      this.remove(child)
    })
    this.destroy()
  }

  onUpdate ({ elapsed }) {
    // const pos1 = polarToCartesian(30, elapsed * .0002)
    // this.directional1.position.x = pos1.x
    // this.directional1.position.z = pos1.y
    // this.directional1.lookAt(0, 0, 0)
  }
}