<template>
  <div class="u-absolute" ref="root" :style="{opacity: opacity}">
    <div class="chapter u-bg-white u-pad-sm u-radius t-text" >
      {{ data.title }}
    </div>
  </div>
</template>

<script>
import Object2d from '@/plugins/Dom3d/mixin2d'
import Opacity2D from './Opacity2D'

import { webGL } from '@/webGL/WebGL'

export default {
  mixins: [Object2d, Opacity2D],

  props: {
    index: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: () => { return {}}
    }
  },

  created () {
    this._camera = webGL.camera

    this.opacityOut = {
      min: 0,
      max: 0
    }
  },

  methods: {
    onUpdate () {
      this.computeOpacity()
      this._onUpdate()
    }
  },
}
</script>

<style lang="stylus">
.chapter
  position absolute
  width 200px
  left -100px
</style>